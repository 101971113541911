import vSelect from 'vue-select'
import { booking } from '@/api/booking'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import AddGolfService from '@/views/modules/booking/SidebarCheckout/AddGolfService/AddGolfService.vue'
import ShortKey from '@/views/modules/ultils/ShortKey'
const {gzip, ungzip} = require('node-gzip');
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, F, A, S,
} from '@/api/constant.js'
import VerifyServiceNoFolio from '@/views/modules/booking/SidebarCheckout/VerifyServiceNoFolio.vue'
import ModalUpdateCaddyNoAssigneeService from '@/views/modules/booking/SidebarCheckout/ModalUpdateCaddyNoAssigneeService.vue'
import UpdateIssueEInvoice from '@/views/modules/booking/SidebarCheckout/UpdateIssueEInvoice.vue'
import modalViewEInvoice from '@/views/modules/invoice/ListInvoice/modalViewEInvoice.vue'
export default {
  name: 'SidebarCheckout',
  components: {
    vSelect,
    'confirm-dialog': ConfirmDialog,
    ShortKey,
    AddGolfService,
    VerifyServiceNoFolio,
    ModalUpdateCaddyNoAssigneeService,
    UpdateIssueEInvoice,
    modalViewEInvoice,
  },
  props: [
    'bookingDetailData',
    'BkDetail',
    'closePayment',
    'isOpenSileBarDetail',
    'bookingDetailprops',
    'KeyWordDataProps',
    'isPaymentClose',
    'IsPaymentAutoLock',
    'currentFocus',
    'IsShowBagtag',
    'IsAutoDeleteServiceNoFolio',
    'isShowNoteInCheckOut',
    'isShowRateInCheckOut',
    'isShowPrintListInvoice',
    'manualSearchFromBDCBagtagNumber',
    'ColorBookingDetail',
    'ListBookingStatus',
    'colorNote',
    'backgroundColorNote',
    'colorNoteGroup',
    'backgroundColorNoteGroup',
    'CheckOutcolorBDC'
  ],
  data() {
    return {
      selected: null,
      BookingDetail: [],
      BookingDetailServices: [],
      BookingDetailStatistic: [],
      Payment: [],
      ListMember: [],
      MemberPay: null,
      isCheckAllServiceInvoice: false,
      ischeckAllServicesBookDetail: false,
      isGetRBK02CBybookingDetailId: false,
      ConfigIsGuestTakeEinvoice: '',
      searchOptions: {
        BDC: [],
        BagTag: [],
        BookingCode: '',
        GroupName: '',
        KeyWord: '',
        OpenDate: this.getUTC('start', 'day'),
      },
      fields: [
        { key: 'FirstName', label: 'Nguời thanh toán', thClass: 'checkout-payment-th' },
        { key: 'TotalAmount', label: 'Tổng', thClass: 'checkout-payment-th' },
        { key: 'ReceivedAmount', label: 'Còn thiếu', thClass: 'checkout-payment-th' },
        {
          key: 'Actions',
          label: '',
          thStyle: { width: '40px' },
          thClass: 'checkout-payment-th',
        },
      ],
      MemberPay_options: [
        { label: 'One', id: 1 },
        { label: 'Two', id: 2 },
      ],
      dataBooking: null,
      isOpen: false,
      isLoadingCreateInvoice: false,
      lockPayment: false,
      dataCheckOut: null,
      listRemovePaymentId: null,
      allChildComponentClosed: [
        {
          ComponentId: 'modal-payment',
          IsActive: false,
        },
        {
          ComponentId: 'add-more-service-item',
          IsActive: false,
        }
      ],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, F, A, S],
      quickCheckOut: false,
      listBookingDetailIdQuickCheckOut: null,
      openModalPaymentQuick: false,
      addServiceDataDetail: null,
      addServiceBookingDetailId: null,
      dataVerifyService: {
        Type: null,
        Body: null
      },
      BookingDetailNotAssigneeCaddy: [],
      BookingDetailNotAssigneeCaddyProps: [],
      dataPaymentDetail: null,
      selectServiceInRate: false,
      selectServiceInvoiceInRate: false,
      selectAllInvoice: false,
      selectServiceNotInRate: false,
      isLoadding: false,
      isShowFilterBookingStatus: false,
      dataViewDetailEInvoice: {
        dataInvoice: null,
        urlInvoice: null,
      },
      checkModuleEInvocie: JSON.parse(localStorage.getItem('Einvoice')),
    }
  },
  computed: {
    ALL() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'ALL') {
          return item.Value
        }
      }
    },
    CHECK_OUT() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'CHECK_OUT') {
          return item.Value
        }
      }
    },
    FULL_PAY() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'FULL_PAY') {
          return item.Value
        }
      }
    },
    NOT_PAID_IN_FULL() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'NOT_PAID_IN_FULL') {
          return item.Value
        }
      }
    },

  },
  watch: {
    bookingDetailData: {
      handler(val, oldVal) {
        this.resetData()
        // if (this.bookingDetailData.bookingDetailId) {
        //   this.getRBK02CBybookingDetailId()
        // }
      },
      deep: true,
    },
    isOpen(val) {
      this.isShowFilterBookingStatus = false
      if (val) {
         this.getConfigInfo_RCOM01()
        setTimeout(() => {
          if (this.currentFocus == 'BAGTAG') {
            this.$refs.listBagtag.$refs.search.focus()
          }
          if (this.currentFocus == 'BDC') {
            this.$refs.listBDC.$refs.search.focus()
          }
        }, 10)
      } else {
        this.hideSideBar()
        // reset lại list bdc khi đóng sidebar
        this.searchOptions.BDC = []
        this.searchOptions.BagTag = []
      }
      this.$emit('is-open', { ComponentId: 'sidebar-checkout', IsActive: val })
    },
    isPaymentClose() {
      this.allChildComponentClosed[0].IsActive = this.isPaymentClose
    },
    searchOptions: {
      handler(val, oldVal) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
          // this.isLoadding = true
          if (val.GroupName || val.KeyWord || val.BookingCode) {
            this.BookingDetail = []
            this.BookingDetailServices = []
            this.Payment = []
            this.isCheckAllServiceInvoice = false
            this.ischeckAllServicesBookDetail = false
            this.selectServiceInRate = false
            this.selectServiceNotInRate = false
            await this.getRBK02CNotBybookingDetailId()

            // if (!val.GroupName && !val.KeyWord && !val.BookingCode) {
            //   // this.$refs.listBDC.$refs.search.focus()
            // }
            
            
          } else if (val.BDC.length || val.BagTag.length) {
            if(val.BDC.length >= this.manualSearchFromBDCBagtagNumber || val.BagTag.length >= this.manualSearchFromBDCBagtagNumber) {
                if (this.currentFocus == 'BAGTAG') {
                  this.$refs.listBagtag.$refs.search.focus()
                }
                if (this.currentFocus == 'BDC') {
                  this.$refs.listBDC.$refs.search.focus()
                }
            } else {
              this.BookingDetail = []
              this.BookingDetailServices = []
              this.Payment = []
              this.isCheckAllServiceInvoice = false
              this.ischeckAllServicesBookDetail = false
              this.selectServiceInRate = false
              this.selectServiceNotInRate = false
              await this.getRBK02CNotBybookingDetailId()
              if (val.BDC.length || val.BagTag.length) {
                if (this.currentFocus == 'BAGTAG') {
                  this.$refs.listBagtag.$refs.search.focus()
                }
                if (this.currentFocus == 'BDC') {
                  this.$refs.listBDC.$refs.search.focus()
                }
              }
            }
            
          } else if (this.bookingDetailData) {
            if(this.bookingDetailData.bookingDetailId) {
              await this.getRBK02CBybookingDetailId()
            }
          }
        }, 500)
      },
      deep: true,
    },
    // Khi tạo mới 1 thanh toán, tương đương với close modal payment
    closePayment() {
      if (this.searchOptions.GroupName || this.searchOptions.KeyWord || this.searchOptions.BookingCode || this.searchOptions.BDC.length || this.searchOptions.BagTag.length) {
        this.getRBK02CNotBybookingDetailId()
      } else if (this.bookingDetailData.bookingDetailId) {
        this.getRBK02CBybookingDetailId()
      }

      // this.getRBK02CBybookingDetailId()
    },
    
  },
  mounted() {
    // console.log(this.bookingDetailData)
  },
  methods: {
     async searchData(key) {
      if(key) {
        this.currentFocus = key
      }
      if(this.searchOptions.BDC.length == 0 && this.searchOptions.BagTag.length == 0 && !this.bookingDetailData) {
        await this.getRBK02CNotBybookingDetailId()
        if (this.currentFocus == 'BAGTAG') {
          this.$refs.listBagtag.$refs.search.focus()
        }
        if (this.currentFocus == 'BDC') {
          this.$refs.listBDC.$refs.search.focus()
        }
      }
    },
    clearKeyWord(key) {
      if(key == 'BDC') {
        this.searchOptions.BDC = []
      } else {
        this.searchOptions.BagTag = []
      }
      this.searchData(key)
    },
    async checkOut_ICI02() {
      
      const dataCheckOut = []
      const dataCheckCaddy = []
      // const dataLockPayment = []
      // Kiểm tra checkout hàng loạt hay 1 người từ chi tiết

      this.BookingDetail.forEach(x => {
        if (x.checked && x.BookingDetailStatus === 'CHECK_IN') {
          dataCheckOut.push(x.Id)
          if(this.BookingDetailNotAssigneeCaddy.find(bkcaddy => bkcaddy.BookingDetailId === x.Id)) {
            dataCheckCaddy.push(this.BookingDetailNotAssigneeCaddy.find(bkcaddy => bkcaddy.BookingDetailId === x.Id))
          }
        }
        // if(x.LockPaymentCode === "UN_LOCK") {
        //   dataLockPayment.push(x.Id)
        // }
      })
      this.BookingDetailNotAssigneeCaddyProps = dataCheckCaddy
      // if(this.IsPaymentAutoLock == '1' && dataLockPayment.length > 0) {
      //   await this.lockPaymentDetail(dataLockPayment,true)
      // }
      this.dataCheckOut = dataCheckOut

      // const listRemoveInvoice = this.Payment.filter(x => x.IsDeleted === false && x.ReceivedAmount === 0 && x.TotalAmount === 0)
      const listRemovePaymentId = []
      this.Payment.filter(x => x.BookingDetailServiceId.length === 0 && x.IsDeleted === false && x.ReceivedAmount === 0 && x.TotalAmount === 0).forEach(y => {
        listRemovePaymentId.push(y.Id)
      })
      this.listRemovePaymentId = listRemovePaymentId
      if (dataCheckOut.length) {
        if(dataCheckCaddy.length > 0) {
          let check = true
          for(let i = 0; i < dataCheckCaddy.length; i++) {
            if(dataCheckCaddy[i].BookingDetail.BookingDetailCaddy.length === 0) {
              this.showToast('error', this.$t('golf_booking_please_select_caddy_fit_service_booking_caddy'))
              check = false
              break
            }
          }
          if(check) {
            this.$bvModal.show('modal-update-caddy-book-no-assignee-service')
          }
          return
        }
        if (listRemovePaymentId.length > 0) {
          this.$refs.confirmRemoveInvoiceComponent.open({
            dataConfirm: {
              title: this.$t('golf_booking_delete_invocie'),
              content: this.$t('golf_booking_confirm_delete_invocie_0d'),
            },
          })
        } else {
          this.api_checkOut_ICI02(dataCheckOut)
        }
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_person_to_check_out'))
      }
    },
    async api_checkOut_ICI02(dataCheckOut) {
      const body = {
        BookingDetailId: dataCheckOut,
      }
      const response = await booking.api_ICI02(body)
      this.showResToast(response)
      if (response.Status == 200) {
        this.$emit('event', { type: 'after-checkout-from-sidebar-checkout' })
        // this.getRBK02CBybookingDetailId()

        if (this.searchOptions.GroupName || this.searchOptions.KeyWord || this.searchOptions.BookingCode || this.searchOptions.BDC.length || this.searchOptions.BagTag.length) {
          this.getRBK02CNotBybookingDetailId()
        } else if (this.bookingDetailData.bookingDetailId) {
          this.getRBK02CBybookingDetailId()
        }
      }
    },
    async removeInvoice0d_api_DPM09(listPaymentId) {
      const body = {
        PaymentId: listPaymentId,
      }
      await booking.api_DPM09(body)
    },
    async RemoveInvoice() {
      await this.removeInvoice0d_api_DPM09(this.listRemovePaymentId)
      await this.api_checkOut_ICI02(this.dataCheckOut)
    },
    fillColor(id,index,index1) {
      let color = ''
      if (this.Payment.length > 0 && id) {
        for (const e of this.Payment) {
          if (e.BookingDetailServiceId.includes(id)) {
            color = e.Color
            this.BookingDetail[index].BookingDetailServices[index1].PaymentId = e.Id
            break
          } else {
            this.BookingDetail[index].BookingDetailServices[index1].PaymentId = null
          }
        }
      }
      return color
    },
    fillColorPayment(id) {
      let color = ''
      if (this.Payment.length > 0 && id) {
        if (this.Payment.filter(x => x.Id == id).length) {
          color = this.Payment.find(x => x.Id == id).Color
        }
      }
      return color
    },
    resetData() {
      const initialData = this.$options.data.call(this)
      Object.assign(this.$data, initialData)
    },
    getAllBookingDetailServices() {
      const BookingDetailServices = []
      // lay ra tat ca cac service, va total amount cua no
      for (const BookingDetail of this.BookingDetail) {
        const markArr = []
        for (let i = 0; i < BookingDetail.BookingDetailServices.length; i++) {
          if (markArr.includes(BookingDetail.BookingDetailServices[i].ServiceId)) {
            continue
          }
          const temp = {}
          temp.ServiceId = BookingDetail.BookingDetailServices[i].ServiceId
          temp.ServiceName = BookingDetail.BookingDetailServices[i].ServiceName
          temp.Quantity = BookingDetail.BookingDetailServices[i].Quantity
          temp.TotalAmount = BookingDetail.BookingDetailServices[i].TotalAmount // ? BookingDetail.BookingDetailServices[i].TotalAmount : BookingDetail.BookingDetailServices[i].Amount
          if (i != BookingDetail.BookingDetailServices.length - 1) {
            for (let j = i + 1; j < BookingDetail.BookingDetailServices.length; j++) {
              if (temp.ServiceId == BookingDetail.BookingDetailServices[j].ServiceId) {
                markArr.push(temp.ServiceId)
                temp.Quantity += BookingDetail.BookingDetailServices[j].Quantity
                temp.TotalAmount += BookingDetail.BookingDetailServices[j].TotalAmount // ? BookingDetail.BookingDetailServices[j].TotalAmount : BookingDetail.BookingDetailServices[j].Amount
              }
            }
          }
          if (BookingDetailServices.length == 0) {
            BookingDetailServices.push(temp)
          } else {
            for (const [index, BookingDetailService] of BookingDetailServices.entries()) {
              if (temp.ServiceId == BookingDetailService.ServiceId) {
                BookingDetailServices[index].TotalAmount += temp.TotalAmount
                BookingDetailServices[index].Quantity += temp.Quantity
                break
              }
              if (index == BookingDetailServices.length - 1) {
                BookingDetailServices.push(temp)
                break
              }
            }
          }
        }
      }
      this.BookingDetailServices = BookingDetailServices
    },
    getStatusText(status) {
      switch (status) {
        case 'CHECK_IN':
          return 'CheckIn'
        case 'CHECK_OUT':
          return 'CheckOut'
        case 'CANCELLED':
          return 'Cancelled'
        case 'NO_SHOW':
          return 'No-Show'
        default:
          return 'Booked'
      }
    },
    hideSideBar() {
      if (this.isOpenSileBarDetail) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-checkout')
        // this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')
        this.$emit('event', { type: 'close-sidebar-checkout' })
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-checkout')
      }
      // Đóng sidebar checkout

      // Hiện lại sidebar detail
      // this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking')

      // this.$emit('event', { type: 'close-sidebar-checkout', Id: this.BkDetail.Id, TeeTimeId: this.BkDetail.TeeTimeId })
    },
    async getRBK02CBybookingDetailId() {
      this.isLoadding = true
      this.isGetRBK02CBybookingDetailId = true
      this.BookingDetail = []
      this.BookingDetailStatistic = []
      // await this.bookingDetailData.bookingDetailId.forEach(async item => {
      const data = {
        BookingDetailId: [...this.bookingDetailData.bookingDetailId],
        BookingDetailStatus: this.ListBookingStatus.filter(x => x.check == true).map(x => x.value)
      }
      await booking.api_RBK02D(data).then(async response => {
        if (response.Status === '200') {
          this.dataPaymentDetail = []
          let listServiceNoFolio = []
            let listBookingDetailNotAssigneeCaddy = []
            for (const bookingDetail of response?.Data?.BookingDetail) {
              for (const Payment of bookingDetail.Payment) {
                for (const Paymentdetail of Payment.PaymentDetail) {
                  Paymentdetail.RootBookingDetailId = bookingDetail.Id
                  this.dataPaymentDetail.push(
                    Paymentdetail
                  )
                }
              }
              let listServiceNotAssigneeCaddy = []
              for (const bookingDetailService of bookingDetail.BookingDetailServices) {
                bookingDetailService.checked = false

                if(bookingDetailService.IsWarning) {
                  listServiceNoFolio.push(bookingDetailService.Id)
                }
                if(bookingDetailService.IsNotAssigneeCaddy) {
                  listServiceNotAssigneeCaddy.push(bookingDetailService)
                }
              }
              if(listServiceNotAssigneeCaddy.length > 0) {
                bookingDetail.BookingDetailCaddy = bookingDetail.BookingDetailCaddy.filter(x => x.BookingDetailService.length === 0)
                if(bookingDetail.BookingDetailCaddy.length === listServiceNotAssigneeCaddy.length) {
                  bookingDetail.BookingDetailCaddy.forEach(x => {
                    x.checked = true
                  })
                }
                listBookingDetailNotAssigneeCaddy.push(
                  {
                    BookingDetailId: bookingDetail.Id,
                    BookingDetail: bookingDetail,
                    BookingDetailService: listServiceNotAssigneeCaddy
                  }
                )
              }
            }
            this.BookingDetailNotAssigneeCaddy = listBookingDetailNotAssigneeCaddy
          if(this.IsAutoDeleteServiceNoFolio == 1) {
            if(listServiceNoFolio.length > 0) {
              this.deleteServiceNoFolio_RPM01F(listServiceNoFolio)
              return
            }
          }
          this.BookingDetail = [...response?.Data?.BookingDetail]
          this.BookingDetailStatistic = [...this.BookingDetailStatistic, ...response?.Data?.BookingDetailStatistic]
          await this.getRPM01()
          await this.getAllBookingDetailServices()
          await this.getListMember()
          this.isLoadding = false
          // if (this.BookingDetail.length === 1) {
          //   this.isCheckAllServiceInvoice = true
          //   this.checkAllServicesInvoice()
          // }
        }
      })
      // })
    },
    async getRBK02CNotBybookingDetailId() {
      // this.isGetRBK02CBybookingDetailId = false
      this.isLoadding = true
      this.BookingDetail = []
      this.BookingDetailStatistic = []
      this.searchOptions.BookingDetailStatus = this.ListBookingStatus.filter(x => x.check == true).map(x => x.value)
      await booking.api_RBK02D(this.searchOptions).then(async response => {
        if (response.Status === '200') {
          if(response.Messages.length > 0) {
            this.showResToast(response)
          }
          this.dataPaymentDetail = []
          let listServiceNoFolio = []
            let listBookingDetailNotAssigneeCaddy = []
            for (const bookingDetail of response?.Data?.BookingDetail) {
              for (const Payment of bookingDetail.Payment) {
                for (const Paymentdetail of Payment.PaymentDetail) {
                  Paymentdetail.RootBookingDetailId = bookingDetail.Id
                  this.dataPaymentDetail.push(
                    Paymentdetail
                  )
                }
              }
              let listServiceNotAssigneeCaddy = []
              for (const bookingDetailService of bookingDetail.BookingDetailServices) {
                bookingDetailService.checked = false

                if(bookingDetailService.IsWarning) {
                  listServiceNoFolio.push(bookingDetailService.Id)
                }
                if(bookingDetailService.IsNotAssigneeCaddy) {
                  listServiceNotAssigneeCaddy.push(bookingDetailService)
                }
              }
              if(listServiceNotAssigneeCaddy.length > 0) {
                bookingDetail.BookingDetailCaddy = bookingDetail.BookingDetailCaddy.filter(x => x.BookingDetailService.length === 0)
                if(bookingDetail.BookingDetailCaddy.length === listServiceNotAssigneeCaddy.length) {
                  bookingDetail.BookingDetailCaddy.forEach(x => {
                    x.checked = true
                  })
                }
                listBookingDetailNotAssigneeCaddy.push(
                  {
                    BookingDetailId: bookingDetail.Id,
                    BookingDetail: bookingDetail,
                    BookingDetailService: listServiceNotAssigneeCaddy
                  }
                )
              }
            }
            this.BookingDetailNotAssigneeCaddy = listBookingDetailNotAssigneeCaddy
          if(this.IsAutoDeleteServiceNoFolio == 1) {
            if(listServiceNoFolio.length > 0) {
              this.deleteServiceNoFolio_RPM01F(listServiceNoFolio)
              return
            }
          }
          // Fix bug double BDC khi xóa thanh toán hóa đơn sân chính 09/09/2024
          //this.BookingDetail = [...this.BookingDetail, ...response?.Data?.BookingDetail]
          this.BookingDetail = [...response?.Data?.BookingDetail]
          this.BookingDetailStatistic = [...this.BookingDetailStatistic, ...response?.Data?.BookingDetailStatistic]
          await this.getRPM01()
          await this.getAllBookingDetailServices()
          await this.getListMember()
          this.isLoadding = false
          // if (this.BookingDetail.length === 1) {
          //   this.isCheckAllServiceInvoice = true
          //   this.checkAllServicesInvoice()
          // }
        }
      })
    },
    async deleteServiceNoFolio_RPM01F(data) {
      const body = {
        BookingDetailServiceId: [...data],
        IsDelete: true
      }
      await booking.api_RPM01F(body)
      .then(res => {
        if (this.searchOptions.GroupName || this.searchOptions.KeyWord || this.searchOptions.BookingCode || this.searchOptions.BDC.length || this.searchOptions.BagTag.length) {
          this.getRBK02CNotBybookingDetailId()
        } else if (this.bookingDetailData.bookingDetailId) {
          this.getRBK02CBybookingDetailId()
        }
      })

    },
    openModalDeletePayment(id) {
      this.$emit('event', { type: 'open-modal-delete-payment', paymentId: id })
    },
    handleEvent(result) {
      if (result.type == 'after-add-new-service-item') {
        if (this.searchOptions.GroupName || this.searchOptions.KeyWord || this.searchOptions.BookingCode || this.searchOptions.BDC.length || this.searchOptions.BagTag.length) {
          this.getRBK02CNotBybookingDetailId()
        } else if (this.bookingDetailData.bookingDetailId) {
          this.getRBK02CBybookingDetailId()
        }
      }
     // sau khi verify kiếm tra xem dịch vụ có nằm trong hệ thông folio hay không?
      if(result.type == 'after-verify-service') {
        if(result.listBookingDetailServiceId.length > 0) {
          setTimeout(() => {
            this.dataVerifyService = {
              Type: 'Delete',
              Body: {
                BookingDetailServiceId: [...result.listBookingDetailServiceId],
                IsDelete: true
              }
            }
            this.$bvModal.show('modal-verify-service')
          },1000)
        } else {
          this.showToast('success', this.$t('golf_sidebar_checkout_verify_service_success'))
          if (this.searchOptions.GroupName || this.searchOptions.KeyWord || this.searchOptions.BookingCode || this.searchOptions.BDC.length || this.searchOptions.BagTag.length) {
            this.getRBK02CNotBybookingDetailId()
          } else if (this.bookingDetailData.bookingDetailId) {
            this.getRBK02CBybookingDetailId()
          }
        }
      }

      // sau khi xóa dịch vụ không có trong hệ thống folio
      if(result.type == 'after-delete-service') {
        if (this.searchOptions.GroupName || this.searchOptions.KeyWord || this.searchOptions.BookingCode || this.searchOptions.BDC.length || this.searchOptions.BagTag.length) {
          this.getRBK02CNotBybookingDetailId()
        } else if (this.bookingDetailData.bookingDetailId) {
          this.getRBK02CBybookingDetailId()
        }
      }
      if(result.type == 'after-publish-einvoice') {
        // console.log('chay')
         this.getRPM01()
      }
      if(result.type == 'after-update-caddy') {
        // console.log('chay')
        if (this.searchOptions.GroupName || this.searchOptions.KeyWord || this.searchOptions.BookingCode || this.searchOptions.BDC.length || this.searchOptions.BagTag.length) {
          this.getRBK02CNotBybookingDetailId()
        } else if (this.bookingDetailData.bookingDetailId) {
          this.getRBK02CBybookingDetailId()
        }
      }
    },
    async getRPM01() {
      const BookingDetail = []
      this.BookingDetail.forEach(item => {
        BookingDetail.push(item.Id)
      })
      if (BookingDetail.length > 0) {
        const data = {
          BookingDetailId: BookingDetail,
        }
        await booking.getRPM01(data).then(response => {
          if (response.Status === '200') {
            if (this.BookingDetail.length === 1) {
              if (response.Data.Payment.length == 0) {
                this.isCheckAllServiceInvoice = true
                this.checkAllServicesInvoice()
              } else {
                let listServiceInInvoiceTemp = []
                response.Data.Payment.forEach(payment => {
                  listServiceInInvoiceTemp = [...listServiceInInvoiceTemp, ...payment.BookingDetailServiceId]
                })
                this.BookingDetail.forEach(bookingDetail => {
                  bookingDetail.BookingDetailServices.forEach(BookingDetailServices => {
                    if (listServiceInInvoiceTemp.find(x => x === BookingDetailServices.Id)) {
                      BookingDetailServices.checked = false
                    } else {
                      BookingDetailServices.checked = true
                    }
                  })
                })
              }
            }
            this.Payment = response?.Data?.Payment.filter(x => x.IsDeleted === false)
            this.Payment.forEach(x => x.checked = false)
            if (this.Payment.filter(x => x.IsDeleted === false).length === 1) {
              const dataPayment = this.Payment.filter(x => x.IsDeleted === false)[0]
              let checkNumber = 0
              let checkBookingDetailService = []
              const listBookingDetailServiceId = JSON.parse(JSON.stringify(dataPayment.BookingDetailServiceId))
              const listBookingDetailId = []
              this.BookingDetail.forEach(x => {
                listBookingDetailId.push(x.Id)
                checkBookingDetailService = [...checkBookingDetailService, ...x.BookingDetailServices]
                x.BookingDetailServices.forEach(y => {
                  listBookingDetailServiceId.forEach((z, index) => {
                    if (y.Id === z) {
                      checkNumber += 1
                      listBookingDetailServiceId.splice(index, 1)
                    }
                  })
                })
              })
              if (listBookingDetailServiceId.length === 0 && checkBookingDetailService.length === checkNumber) {
                if (this.openModalPaymentQuick) {
                  this.quickCheckOut = true
                  this.listBookingDetailIdQuickCheckOut = listBookingDetailId
                  this.createPermanentInvoice(dataPayment)
                  this.openModalPaymentQuick = false
                } else {
                  this.quickCheckOut = true
                  this.listBookingDetailIdQuickCheckOut = listBookingDetailId
                }
              } else {
                this.quickCheckOut = false
                this.openModalPaymentQuick = false
                this.listBookingDetailIdQuickCheckOut = null
              }
            } else {
              this.quickCheckOut = false
              this.openModalPaymentQuick = false
              this.listBookingDetailIdQuickCheckOut = null
            }
          }
        })
      }
    },
    getTotalAmount(BookingDetailServices) {
      let total = 0
      BookingDetailServices.forEach(item => {
        total += item.TotalAmount ? item.TotalAmount : 0 // item.Amount
      })
      return total
    },
    getARest(BookingDetailServices, Payment) {
      let total = 0
      BookingDetailServices.forEach(item => {
        total += item.TotalAmount ? item.TotalAmount : 0 // item.Amount
      })

      let paid = 0

      Payment.forEach(x => {
        x.PaymentDetail.forEach(y => {
          paid += y.Amount ? y.Amount : 0
        })
      })

      return total - paid
    },
    handleCheckBoxInvoice(ServiceId) {
      let isCheck = false
      for (const BookingDetailService of this.BookingDetailServices) {
        if (BookingDetailService.ServiceId == ServiceId) {
          isCheck = BookingDetailService.checked
        }
      }

      const tmp = [...this.BookingDetail]
      for (const bookingDetail of tmp) {
        for (const bookingDetailService of bookingDetail.BookingDetailServices) {
          if (bookingDetailService.ServiceId === ServiceId) {
            bookingDetailService.checked = isCheck
          }
        }
      }
      this.BookingDetail = Object.assign([], tmp)
    },
    checkAllServicesInvoice() {
      let tmp = [...this.BookingDetail]
      for (const bookingDetail of tmp) {
        bookingDetail.checked = this.isCheckAllServiceInvoice
        for (const bookingDetailService of bookingDetail.BookingDetailServices) {
          bookingDetailService.checked = this.isCheckAllServiceInvoice
        }
        for (const payment of bookingDetail.Payment) {
          for (const PaymentDetail of payment.PaymentDetail) {
            PaymentDetail.checked = bookingDetail.checked
          }
        }
      }
      this.BookingDetail = Object.assign([], tmp)

      tmp = [...this.BookingDetailServices]
      for (const bookingDetailService of tmp) {
        bookingDetailService.checked = this.isCheckAllServiceInvoice
      }
      this.BookingDetailServices = Object.assign([], tmp)
    },
    checkAllServicesBookDetail(BookingDetailId) {
      const tmp = [...this.BookingDetail]
      for (const bookingDetail of tmp) {
        if (bookingDetail.Id == BookingDetailId) {
          for (const bookingDetailService of bookingDetail.BookingDetailServices) {
            bookingDetailService.checked = bookingDetail.checked
          }
          for (const payment of bookingDetail.Payment) {
            for (const PaymentDetail of payment.PaymentDetail) {
              PaymentDetail.checked = bookingDetail.checked
            }
          }
          break
        }
      }
      this.BookingDetail = Object.assign([], tmp)
    },
    async createTemporaryInvoice() {
      const options = {
        Status: 'Temporary',
      }

      const callback = async payment => {
        await booking.createPayment(payment).then(async response => {
          if (response.Status === '200') {
            this.openModalPaymentQuick = true
            // if (this.isGetRBK02CBybookingDetailId) { this.getRBK02CBybookingDetailId() } else { this.getRBK02CNotBybookingDetailId() }

            if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
              this.getRBK02CNotBybookingDetailId()
            } else {
              this.getRBK02CBybookingDetailId()
            }
          }
          this.showResToast(response)
          this.isLoadingCreateInvoice = false
        })
      }
      this.isLoadingCreateInvoice = true
      this.createInvoice(options, callback)
    },
    async createPermanentInvoice(data) {
      const listServiceNoFolio = []
      for (const bookingDetail of this.BookingDetail) {
        for (const bookingDetailService of bookingDetail.BookingDetailServices) {
          if(bookingDetailService.IsWarning) {
            listServiceNoFolio.push(bookingDetailService.Id)
          }
        }
      }
      if(listServiceNoFolio.length > 0) {
        this.verifyServiceNoFoilio(listServiceNoFolio)
        return
      }
      this.$emit('event', {
        type: 'open-modal-payment', paymentId: data.Id, quickCheckOut: this.quickCheckOut, listBookingDetailIdQuickCheckOut: this.listBookingDetailIdQuickCheckOut, BookingDetailId: data.BookingDetailId, clientId: data.ClientId, sourceId: data.SourceId, dataRBK02D: this.dataPaymentDetail
      })
    },
    async createInvoice(options, callback) {
      if (!this.MemberPay) {
        this.isLoadingCreateInvoice = false
        return this.showToast('error', this.$t('golf_sidebar_checkout_please_select_person_payment'))
      }
      const paymentData = {}
      let isNotServiceEmpty = false
      let BookingDetailLockPayment = []
      paymentData.MemberId = this.MemberPay.id
      paymentData.BookingDetailId = this.MemberPay.bookingDetailId

      paymentData.PaymentCode = ''
      paymentData.FiscalPaymentDate = this.getUTC('start', 'day')
      paymentData.PaymentDate = this.getUTC('start', 'day')
      // paymentData['PaymentStatus'] = "Pay"
      paymentData.Status = options.Status
      paymentData.PaymentBooking = []
      paymentData.PaymentDiscount = []
      paymentData.PaymentDetail = []
      for (const bookingDetail of this.BookingDetail) {
        const PaymentBooking = {}
        PaymentBooking.BookingId = bookingDetail.BookingId
        PaymentBooking.PaymentBookingDetail = []
        const PaymentBookingDetail = {}

        PaymentBookingDetail.PaymentBookingDetailService = []
        for (const bookingDetailService of bookingDetail.BookingDetailServices) {
          if (bookingDetailService.checked) {
            const PaymentBookingDetailService = {

              ServiceId: bookingDetailService.ServiceId,
              UnitPrice: bookingDetailService.UnitPrice,
              Quantity: bookingDetailService.Quantity,
              Amount: bookingDetailService.Amount,
              TotalAmount: bookingDetailService.TotalAmount,
              RateId: bookingDetailService.RateId,
              PaymentBookingDetailServiceDiscount: [],
              ServiceType: bookingDetailService.ServiceType,
              BookingDetailServiceId: bookingDetailService.Id,
              ZoneId: bookingDetailService.ZoneId,
            }
            PaymentBookingDetail.PaymentBookingDetailService.push(PaymentBookingDetailService)
          }
        }

        bookingDetail.Payment.forEach(element => {
          for (const pay of element.PaymentDetail) {
            if (pay.checked) {
              paymentData.PaymentDetail.push({
                Id: pay.Id,
              })
            }
          }
        })

        if (!PaymentBookingDetail.PaymentBookingDetailService.length) {
          isNotServiceEmpty = isNotServiceEmpty || false
        } else {
          PaymentBookingDetail.BookingDetailId = bookingDetail.Id
          isNotServiceEmpty = isNotServiceEmpty || true
          if(bookingDetail.LockPaymentCode === "UN_LOCK") {
            BookingDetailLockPayment.push(bookingDetail.Id)
          }
        }
        if (PaymentBookingDetail.BookingDetailId) {
          PaymentBooking.PaymentBookingDetail.push(PaymentBookingDetail)
          paymentData.PaymentBooking.push(PaymentBooking)
        }
      }
      
      if (!isNotServiceEmpty) {
        this.isLoadingCreateInvoice = false
        return this.showToast('error', this.$t('golf_sidebar_checkout_select_at_least_a_sevice'))
      }
      if(this.IsPaymentAutoLock == '1' && BookingDetailLockPayment.length > 0) {
        await this.lockPaymentDetail(BookingDetailLockPayment,true,true)
      }
      const payment = {}
      payment.Payment = paymentData
      callback(payment)
    },
    calConThieu(Options) {
      return Options.TotalAmount - Options.ReceivedAmount
    },
    getTotalPaymentReceivedAmount() {
      let total = 0
      for (const payment of this.Payment) {
        total += payment.ReceivedAmount
      }
      return total
    },
    getListMember() {
      const listMember = this.BookingDetail.filter((value, index, self) => index === self.findIndex(t => (
        t.MemberId === value.MemberId && t.FullName === value.FullName
      ))).map(item => ({
        id: item.MemberId,
        label: item.FullName,
        bookingDetailId: item.Id,
      }))
      const itemPayPerPerson = [{
        id: -99,
        label: this.$t('golf_payment_per_person'),
        bookingDetailId: null,
      }]
      this.ListMember = itemPayPerPerson.concat(listMember)
      if (this.ListMember.length > 1) {
        this.MemberPay = this.ListMember[1]
      } 
    },
    getMemberPayString(Options) {
      return `#${Options.Id} ${Options.FullName}`
    },
    call_api_UPM02(data) {
      const body = {
        Payment: {
          Id: data.Id,
        },
      }
      booking.api_UPM02(body).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.getRPM01()
        }
      })
    },
    async moveInvoice(paymentId) {
      const paymentData = {}
      let BookingDetailLockPayment = []
      let isNotServiceEmpty = false
      // paymentData.Status = 'Temporary' // options.Status
      paymentData.PaymentBooking = []
      paymentData.PaymentDiscount = []
      paymentData.PaymentDetail = []
      for (const bookingDetail of this.BookingDetail) {
        const PaymentBooking = {}
        PaymentBooking.BookingId = bookingDetail.BookingId
        PaymentBooking.PaymentBookingDetail = []
        const PaymentBookingDetail = {}

        PaymentBookingDetail.PaymentBookingDetailService = []
        for (const bookingDetailService of bookingDetail.BookingDetailServices) {
          if (bookingDetailService.checked) {
            const PaymentBookingDetailService = {

              ServiceId: bookingDetailService.ServiceId,
              UnitPrice: bookingDetailService.UnitPrice,
              Quantity: bookingDetailService.Quantity,
              Amount: bookingDetailService.Amount,
              TotalAmount: bookingDetailService.TotalAmount,
              RateId: bookingDetailService.RateId,
              PaymentBookingDetailServiceDiscount: [],
              ServiceType: bookingDetailService.ServiceType,
              BookingDetailServiceId: bookingDetailService.Id,
              ZoneId: bookingDetailService.ZoneId,
            }
            PaymentBookingDetail.PaymentBookingDetailService.push(PaymentBookingDetailService)
          }
        }

        bookingDetail.Payment.forEach(element => {
          for (const pay of element.PaymentDetail) {
            if (pay.checked) {
              paymentData.PaymentDetail.push({
                Id: pay.Id,
              })
            }
          }
        })

        if (!PaymentBookingDetail.PaymentBookingDetailService.length) {
          isNotServiceEmpty = isNotServiceEmpty || false
        } else {
          PaymentBookingDetail.BookingDetailId = bookingDetail.Id
          isNotServiceEmpty = isNotServiceEmpty || true
          if(bookingDetail.LockPaymentCode === "UN_LOCK") {
            BookingDetailLockPayment.push(bookingDetail.Id)
          }
        }
        if (PaymentBookingDetail.BookingDetailId) {
          PaymentBooking.PaymentBookingDetail.push(PaymentBookingDetail)
          paymentData.PaymentBooking.push(PaymentBooking)
        }
      }
      
      if (!isNotServiceEmpty && paymentData.PaymentDetail.length < 1) {
        return this.showToast('error', this.$t('golf_sidebar_checkout_please_select_sevice'))
      }
      if(this.IsPaymentAutoLock == '1' && BookingDetailLockPayment.length > 0) {
        await this.lockPaymentDetail(BookingDetailLockPayment,true,true)
      }
      const payment = {}
      payment.Payment = paymentData
      payment.Payment.Id = paymentId

      await booking.api_UPM01(payment).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
            this.getRBK02CNotBybookingDetailId()
          } else {
            this.getRBK02CBybookingDetailId()
          }
        }
      })
    },
    async changeStatusToTempInvoice(data) {
      await booking.api_UPM07({ PaymentId: [data.Id] }).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.getRPM01()
        }
      })
    },
    openRIV01(paymentId) {
      const routeData = this.$router.resolve({ name: 'booking-print-invoice', params: { id: paymentId } })
      window.open(routeData.href, '_blank')
    },
    async removeService() {
      let isChecklistServiceInInvoice = true
      const body = {
        BookingDetailServiceId: [],
      }
      this.BookingDetail.forEach(x => {
        x.BookingDetailServices.forEach(y => {
          if (y.checked) {
            body.BookingDetailServiceId.push(y.Id)
          }
        })
      })

      const listServiceInInvoice = []
      this.Payment.forEach(x => {
        x.BookingDetailServiceId.forEach(y => {
          listServiceInInvoice.push(y)
        })
      })

      body.BookingDetailServiceId.forEach(x => {
        if (!listServiceInInvoice.includes(x)) {
          isChecklistServiceInInvoice = false
        }
      })

      if (body.BookingDetailServiceId.length > 0 && isChecklistServiceInInvoice) {
        await booking.api_DPM07(body).then(res => {
          this.showResToast(res)
          if (res.Status == '200') {
            if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
              this.getRBK02CNotBybookingDetailId()
            } else {
              this.getRBK02CBybookingDetailId()
            }
          }
        })
      } else if (!isChecklistServiceInInvoice) {
        this.showToast('error', this.$t('golf_sidebar_checkout_service_not_in_invoice'))
      } else {
        this.showToast('error', this.$t('golf_sidebar_checkout_select_at_least_a_sevice'))
      }
    },
    async lockPaymentDetail(BookingDetailId,checkAll, isReloadData) {
      if(checkAll) {
        var body = {
          BookingDetailId: BookingDetailId,
          LockPayment: true,
        }
      } else {
        var body = {
          BookingDetailId: [BookingDetailId],
          LockPayment: true,
        }
      }
      
      await booking.api_UBK11(body).then(res => {
        if(!isReloadData) {
          this.showResToast(res)
          if (res.Status == '200') {
            if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
              this.getRBK02CNotBybookingDetailId()
            } else {
              this.getRBK02CBybookingDetailId()
            }
          }
        }
      })
    },
    async unLockPaymentDetail(BookingDetailId, checkAll) {
      if(checkAll) {
        var body = {
          BookingDetailId: BookingDetailId,
          LockPayment: false,
        }
      } else {
        var body = {
          BookingDetailId: [BookingDetailId],
          LockPayment: false,
        }
      }
      
      await booking.api_UBK11(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
            this.getRBK02CNotBybookingDetailId()
          } else {
            this.getRBK02CBybookingDetailId()
          }
        }
      })
    },
    findColorBookingStatus(BookingStatus) {
      const color = this.ColorBookingDetail[BookingStatus]
      return color
    },
    clearData() {
      this.BookingDetail = []
      this.BookingDetailServices = []
      this.Payment = []
    },
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === F) {
        if (this.currentFocus == 'BAGTAG') {
          this.$refs.listBagtag.$refs.search.focus()
        }
        if (this.currentFocus == 'BDC') {
          this.$refs.listBDC.$refs.search.focus()
        }
      }
      if (payload.evt.ctrlKey && payload.key === A) {
        this.isCheckAllServiceInvoice = !this.isCheckAllServiceInvoice
        this.checkAllServicesInvoice()
      }
      if (payload.evt.ctrlKey && payload.key === S) {
        this.createTemporaryInvoice()
      }
      if (payload.key === F5) {
        this.searchRBK02DNew()
      }
      // if(payload.key === F4) {
      //   this.hideSideBar()
      // }
    },
    addMoreService(item) {
      this.addServiceBookingDetailId = item.Id
      this.addServiceDataDetail = item
      // console.log(item)
      this.$root.$emit('bv::toggle::collapse', 'add-more-service-item')
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      //const responsePaymentConfig = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      if (response.Status === '200') {
        //console.log(response?.Data.find(x => x.KeyCode === 'BOOKING_MANUAL_SEARCH_FROM_BDC_BAGTAG_NUMBERS').KeyValue)
        //this.currentFocus = response?.Data.find(x => x.KeyCode === 'BOOKING_DEFAULT_SEARCH').KeyValue
        this.ConfigIsGuestTakeEinvoice = response?.Data.find(x => x.KeyCode === 'BOOKING_GUEST_TAKE_EINVOICE_MTT') ? response?.Data.find(x => x.KeyCode === 'BOOKING_GUEST_TAKE_EINVOICE_MTT').KeyValue : null
        //this.IsShowBagtag = response?.Data.find(x => x.KeyCode === 'BOOKING_CHECK_OUT_SHOW_BAGTAG').KeyValue
        //this.IsAutoDeleteServiceNoFolio = response?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_CLEAR_FAIL_SERVICE_FROM_BAR').KeyValue
      }
      // if(responsePaymentConfig.Status === '200') {
      //   this.IsPaymentAutoLock = responsePaymentConfig?.Data.find(x => x.KeyCode === 'PAYMENT_AUTO_LOCK').KeyValue
      // }

    },
    verifyServiceNoFoilio(data) {
      this.dataVerifyService = {
        Type: 'Verify',
        Body: {
          BookingDetailServiceId: [...data],
          IsDelete: false
        }
      }
      this.$bvModal.show('modal-verify-service')
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    blockOrUnBlockPaymentAll(checkLock) {
      const listBookingDetail = []
      this.BookingDetail.forEach(x => {
        if(x.checked === true) {
          listBookingDetail.push(x.Id)
        }
      })
      if(listBookingDetail.length === 0) {
        this.showToast('error', this.$t('golf_sidebar_checkout_select_at_least_a_person'))
        return
      }
      if(checkLock) {
        this.lockPaymentDetail(listBookingDetail,true)
      } else {
        this.unLockPaymentDetail(listBookingDetail,true)
      }
    },
    async RefreshAfterDeletePayment() {
      if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
        await this.getRBK02CNotBybookingDetailId()
        this.$emit('event', { type: 'refresh-after-delete-payment-detail'})
        
      } else {
        await this.getRBK02CBybookingDetailId()
        this.$emit('event', { type: 'refresh-after-delete-payment-detail'})
      }
    },
    selectAllServiceInRate(value) {
      this.BookingDetail.forEach(x => {
        x.BookingDetailServices.forEach(y => {
          if(!y.PaymentId && y.RateId) {
            y.checked = value
          }
        })
      })
    },
    selectAllServiceNotInRate(value) {
      this.BookingDetail.forEach(x => {
        x.BookingDetailServices.forEach(y => {
          if(!y.PaymentId && !y.RateId) {
            y.checked = value
          }
        })
      })
    },
    selectAllServiceInvocieInRate(value) {
      this.BookingDetail.forEach(x => {
        x.BookingDetailServices.forEach(y => {
          if(y.PaymentId && !y.RateId) {
            y.checked = value
          }
        })
      })
    },
    SelectAllInvoice(value) {
      this.Payment.forEach(x => {
        x.checked = value
      })
      this.$forceUpdate()
    },
    PrintListService() {
      const listPaymentId = []
      this.Payment.forEach(x => {
        if(x.checked) {
          listPaymentId.push({
            Id: x.Id,
            Type: "COURSE"
          })
        }
      })
      if(listPaymentId.length > 0) {
        var text = ''
        gzip(JSON.stringify(listPaymentId)).then((res) => {
          text = res.toString('base64')
          const routeData = this.$router.resolve({ name: 'booking-print-list-invoice', params: { id: text } })
          window.open(routeData.href, '_blank')
        })
      } else {
        this.showToast('error', this.$t('golf_sidebar_checkout_select_invoice'))
      }
      
      
      
    },
    searchRBK02DNew() {
      if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
        this.getRBK02CNotBybookingDetailId()
      } else {
        if(this.bookingDetailData.bookingDetailId) {
          this.getRBK02CBybookingDetailId()
        }
      }
    },
    changeFilterBookingStatus() {
      if (this.searchOptions.BookingCode || this.searchOptions.KeyWord || this.searchOptions.GroupName || this.searchOptions.BDC.length > 0 || this.searchOptions.BagTag.length > 0) {
        this.getRBK02CNotBybookingDetailId()
      } else {
        if(this.bookingDetailData.bookingDetailId) {
          this.getRBK02CBybookingDetailId()
        }
      }
      this.isShowFilterBookingStatus = false
    },
    async viewEInvocie_IPM07(Id) {
      const body = {
        PaymentId: Id,
        Type: 'DownloadPDF',
      }
      
      this.isLoadingCreateInvoice = true
      const response = await booking.api_IPM07_PUBLISH_VIEW_EINVOICE_COURSE(body)
      if (response.Status == 200) {
        const decodedData = Buffer.from(response.Data.InvoicePDF, 'base64')
        const newBlob = new Blob([decodedData], {
          type: 'application/pdf',
        })
        const url = window.URL.createObjectURL(newBlob)
        this.dataViewDetailEInvoice.urlInvocie = url
        this.dataViewDetailEInvoice.dataInvoice = response.Data.InvoiceId
        this.$bvModal.show('modal-view-invoice-VAT')
        this.isLoadingCreateInvoice = false
        this.$refs.viewEInvocie.open()
      }
      else {
        this.isLoadingCreateInvoice = false
      }
    },
  },
}
